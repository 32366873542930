// Override default variables before the import
$body-bg: #f5f5f5;

//// http://colormind.io/bootstrap
//$primary: #dda183; //Tumbleweed
//$info: #65687a; // Shuttle Gray
//$success: #78ab5f; // Chelsea Cucumber
//$warning: #f59b27; // Sea Buckthorn
//$danger: #f44336; // Pomegranate

//// https://bootswatch.com/minty/
//$white:    #fff !default;
//$gray-100: #f8f9fa !default;
//$gray-200: #f7f7f9 !default;
//$gray-300: #eceeef !default;
//$gray-400: #ced4da !default;
//$gray-500: #aaa !default;
//$gray-600: #888 !default;
//$gray-700: #5a5a5a !default;
//$gray-800: #343a40 !default;
//$gray-900: #212529 !default;
//$black:    #000 !default;
//
//$blue:    #007bff !default;
//$indigo:  #6610f2 !default;
//$purple:  #6f42c1 !default;
//$pink:    #e83e8c !default;
//$red:     #FF7851 !default;
//$orange:  #fd7e14 !default;
//$yellow:  #FFCE67 !default;
//$green:   #56CC9D !default;
//$teal:    #20c997 !default;
//$cyan:    #6CC3D5 !default;
//
//$primary:       #78C2AD !default;
//$secondary:     #F3969A !default;
//$success:       $green !default;
//$info:          $cyan !default;
//$warning:       $yellow !default;
//$danger:        $red !default;
//$light:         $gray-100 !default;
//$dark:          $gray-800 !default;


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
